import { onMounted, onUnmounted } from 'vue';

export const useModalActive = () => {
    onMounted(() => {
        document.body.classList.add('modal-active');
    });

    onUnmounted(() => {
        document.body.classList.remove('modal-active');
    });
};
